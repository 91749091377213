export default [
    [
        2538757,
        29488340.0
    ],
    [
        2538758,
        106641378.0
    ],
    [
        2538796,
        29488340.0
    ],
    [
        2538797,
        106641378.0
    ],
    [
        2538808,
        29492354.0
    ],
    [
        2538809,
        106637694.0
    ],
    [
        2538822,
        29492354.0
    ],
    [
        2538823,
        106637694.0
    ],
    [
        2538898,
        29492020.0
    ],
    [
        2538900,
        106637823.0
    ],
    [
        2538970,
        29491658.0
    ],
    [
        2538971,
        106638008.0
    ],
    [
        2539002,
        29491750.0
    ],
    [
        2539003,
        106638223.0
    ],
    [
        2539069,
        29491691.0
    ],
    [
        2539070,
        106638038.0
    ],
    [
        2539116,
        29491880.0
    ],
    [
        2539117,
        106638046.0
    ],
    [
        2539140,
        29491911.0
    ],
    [
        2539141,
        106638031.0
    ],
    [
        2539175,
        29491871.0
    ],
    [
        2539176,
        106638006.0
    ],
    [
        2539277,
        29491851.0
    ],
    [
        2539279,
        106638185.0
    ],
    [
        2539361,
        29491738.0
    ],
    [
        2539362,
        106638261.0
    ],
    [
        2539428,
        29491566.0
    ],
    [
        2539429,
        106638038.0
    ],
    [
        2539446,
        29491693.0
    ],
    [
        2539447,
        106637781.0
    ],
    [
        2539468,
        29491699.0
    ],
    [
        2539469,
        106637915.0
    ],
    [
        2539550,
        29491553.0
    ],
    [
        2539551,
        106638126.0
    ],
    [
        2539622,
        29491496.0
    ],
    [
        2539623,
        106638068.0
    ],
    [
        2539726,
        29491474.0
    ],
    [
        2539727,
        106638051.0
    ],
    [
        2539837,
        29491681.0
    ],
    [
        2539838,
        106638070.0
    ],
    [
        2539866,
        29491503.0
    ],
    [
        2539867,
        106638010.0
    ],
    [
        2539923,
        29491454.0
    ],
    [
        2539924,
        106637985.0
    ],
    [
        2539991,
        29491613.0
    ],
    [
        2539992,
        106637890.0
    ],
    [
        2540123,
        29491596.0
    ],
    [
        2540124,
        106637953.0
    ],
    [
        2540208,
        29491949.0
    ],
    [
        2540209,
        106638041.0
    ],
    [
        2540267,
        29491801.0
    ],
    [
        2540268,
        106638110.0
    ],
    [
        2540326,
        29491473.0
    ],
    [
        2540327,
        106638146.0
    ],
    [
        2540481,
        29491235.0
    ],
    [
        2540482,
        106638206.0
    ],
    [
        2540573,
        29491468.0
    ],
    [
        2540574,
        106637970.0
    ],
    [
        2540609,
        29491521.0
    ],
    [
        2540610,
        106637961.0
    ],
    [
        2540697,
        29491488.0
    ],
    [
        2540698,
        106638066.0
    ],
    [
        2540717,
        29491586.0
    ],
    [
        2540718,
        106638031.0
    ],
    [
        2540749,
        29491609.0
    ],
    [
        2540750,
        106637950.0
    ],
    [
        2540858,
        29491588.0
    ],
    [
        2540859,
        106638003.0
    ],
    [
        2540936,
        29491626.0
    ],
    [
        2540937,
        106637964.0
    ],
    [
        2541072,
        29491568.0
    ],
    [
        2541073,
        106637903.0
    ],
    [
        2541104,
        29491606.0
    ],
    [
        2541105,
        106637830.0
    ],
    [
        2541133,
        29491593.0
    ],
    [
        2541134,
        106637768.0
    ],
    [
        2541243,
        29491461.0
    ],
    [
        2541244,
        106637835.0
    ],
    [
        2541283,
        29491315.0
    ],
    [
        2541284,
        106637923.0
    ],
    [
        2541343,
        29491438.0
    ],
    [
        2541344,
        106637934.0
    ],
    [
        2541459,
        29491513.0
    ],
    [
        2541460,
        106637833.0
    ],
    [
        2541730,
        29491458.0
    ],
    [
        2541731,
        106638201.0
    ],
    [
        2541765,
        29491453.0
    ],
    [
        2541766,
        106638296.0
    ],
    [
        2541804,
        29491514.0
    ],
    [
        2541805,
        106638238.0
    ],
    [
        2541828,
        29491558.0
    ],
    [
        2541829,
        106638095.0
    ],
    [
        2541856,
        29491556.0
    ],
    [
        2541857,
        106638188.0
    ],
    [
        2541873,
        29491581.0
    ],
    [
        2541874,
        106638213.0
    ],
    [
        2541972,
        29491589.0
    ],
    [
        2541973,
        106638271.0
    ],
    [
        2542155,
        29491474.0
    ],
    [
        2542156,
        106638015.0
    ],
    [
        2542185,
        29491443.0
    ],
    [
        2542186,
        106637991.0
    ],
    [
        2542211,
        29491446.0
    ],
    [
        2542212,
        106638051.0
    ],
    [
        2542337,
        29491511.0
    ],
    [
        2542338,
        106638051.0
    ],
    [
        2542417,
        29491559.0
    ],
    [
        2542418,
        106638023.0
    ],
    [
        2542438,
        29491586.0
    ],
    [
        2542439,
        106637953.0
    ],
    [
        2542460,
        29491609.0
    ],
    [
        2542461,
        106637950.0
    ],
    [
        2542582,
        29491501.0
    ],
    [
        2542583,
        106638100.0
    ],
    [
        2542718,
        29491643.0
    ],
    [
        2542719,
        106638081.0
    ],
    [
        2542784,
        29491571.0
    ],
    [
        2542785,
        106638168.0
    ],
    [
        2542842,
        29491541.0
    ],
    [
        2542843,
        106637970.0
    ],
    [
        2542951,
        29491453.0
    ],
    [
        2542952,
        106637963.0
    ],
    [
        2543167,
        29491556.0
    ],
    [
        2543168,
        106637861.0
    ],
    [
        2543266,
        29491600.0
    ],
    [
        2543267,
        106637813.0
    ],
    [
        2543322,
        29491514.0
    ],
    [
        2543323,
        106637808.0
    ],
    [
        2543376,
        29491525.0
    ],
    [
        2543377,
        106637940.0
    ],
    [
        2543510,
        29491595.0
    ],
    [
        2543511,
        106637858.0
    ],
    [
        2543606,
        29491561.0
    ],
    [
        2543607,
        106637731.0
    ],
    [
        2543634,
        29491503.0
    ],
    [
        2543635,
        106637904.0
    ],
    [
        2543670,
        29491553.0
    ],
    [
        2543671,
        106637821.0
    ],
    [
        2543707,
        29491550.0
    ],
    [
        2543708,
        106637921.0
    ],
    [
        2543747,
        29491564.0
    ],
    [
        2543748,
        106637814.0
    ],
    [
        2543771,
        29491498.0
    ],
    [
        2543772,
        106637768.0
    ],
    [
        2543897,
        29491519.0
    ],
    [
        2543898,
        106637863.0
    ],
    [
        2543938,
        29491448.0
    ],
    [
        2543939,
        106637773.0
    ],
    [
        2543979,
        29491450.0
    ],
    [
        2543980,
        106637930.0
    ],
    [
        2544007,
        29491473.0
    ],
    [
        2544008,
        106637926.0
    ],
    [
        2544037,
        29491529.0
    ],
    [
        2544038,
        106637723.0
    ],
    [
        2544071,
        29491499.0
    ],
    [
        2544072,
        106637735.0
    ],
    [
        2544114,
        29491481.0
    ],
    [
        2544115,
        106637773.0
    ],
    [
        2544183,
        29491544.0
    ],
    [
        2544184,
        106637779.0
    ],
    [
        2544274,
        29491535.0
    ],
    [
        2544275,
        106637873.0
    ],
    [
        2544325,
        29491580.0
    ],
    [
        2544326,
        106637628.0
    ],
    [
        2544368,
        29491548.0
    ],
    [
        2544370,
        106637656.0
    ],
    [
        2544491,
        29491576.0
    ],
    [
        2544492,
        106637736.0
    ],
    [
        2544533,
        29491556.0
    ],
    [
        2544534,
        106637773.0
    ],
    [
        2544761,
        29491640.0
    ],
    [
        2544762,
        106637936.0
    ],
    [
        2544811,
        29492066.0
    ],
    [
        2544812,
        106637163.0
    ],
    [
        2544876,
        29491840.0
    ],
    [
        2544877,
        106637593.0
    ],
    [
        2544902,
        29491640.0
    ],
    [
        2544903,
        106637940.0
    ],
    [
        2545026,
        29491595.0
    ],
    [
        2545027,
        106638048.0
    ],
    [
        2545132,
        29492146.0
    ],
    [
        2545133,
        106637406.0
    ],
    [
        2545207,
        29492116.0
    ],
    [
        2545208,
        106637329.0
    ],
    [
        2545259,
        29492343.0
    ],
    [
        2545260,
        106637149.0
    ],
    [
        2545325,
        29492475.0
    ],
    [
        2545326,
        106637175.0
    ],
    [
        2545489,
        29491983.0
    ],
    [
        2545490,
        106637708.0
    ],
    [
        2545517,
        29491960.0
    ],
    [
        2545518,
        106637791.0
    ],
    [
        2545554,
        29492045.0
    ],
    [
        2545555,
        106637608.0
    ],
    [
        2545592,
        29492054.0
    ],
    [
        2545593,
        106637706.0
    ],
    [
        2545633,
        29491874.0
    ],
    [
        2545634,
        106637983.0
    ],
    [
        2545662,
        29491645.0
    ],
    [
        2545663,
        106637959.0
    ],
    [
        2545800,
        29491683.0
    ],
    [
        2545801,
        106637976.0
    ],
    [
        2545918,
        29491580.0
    ],
    [
        2545919,
        106637921.0
    ],
    [
        2546026,
        29491825.0
    ],
    [
        2546027,
        106637961.0
    ],
    [
        2546063,
        29491951.0
    ],
    [
        2546064,
        106637956.0
    ],
    [
        2546093,
        29491856.0
    ],
    [
        2546094,
        106637876.0
    ],
    [
        2546211,
        29491876.0
    ],
    [
        2546212,
        106637931.0
    ],
    [
        2546288,
        29491773.0
    ],
    [
        2546289,
        106637945.0
    ],
    [
        2546378,
        0.0
    ],
    [
        2546379,
        0.0
    ],
    [
        2546413,
        29491481.0
    ],
    [
        2546414,
        106638065.0
    ],
    [
        2546445,
        29491320.0
    ],
    [
        2546446,
        106638238.0
    ],
    [
        2546476,
        29492036.0
    ],
    [
        2546477,
        106637930.0
    ],
    [
        2546595,
        29491773.0
    ],
    [
        2546596,
        106638176.0
    ],
    [
        2546655,
        29491640.0
    ],
    [
        2546656,
        106637970.0
    ],
    [
        2546759,
        29491619.0
    ],
    [
        2546760,
        106638058.0
    ],
    [
        2546820,
        29491544.0
    ],
    [
        2546821,
        106638114.0
    ],
    [
        2546838,
        29491495.0
    ],
    [
        2546839,
        106638253.0
    ],
    [
        2546869,
        29491480.0
    ],
    [
        2546870,
        106638026.0
    ],
    [
        2546901,
        29491640.0
    ],
    [
        2546902,
        106638081.0
    ],
    [
        2547060,
        29491633.0
    ],
    [
        2547061,
        106638116.0
    ],
    [
        2547142,
        29491548.0
    ],
    [
        2547143,
        106638191.0
    ],
    [
        2547162,
        29491525.0
    ],
    [
        2547163,
        106638054.0
    ],
    [
        2547223,
        29491373.0
    ],
    [
        2547224,
        106638108.0
    ],
    [
        2547241,
        29491286.0
    ],
    [
        2547242,
        106638028.0
    ],
    [
        2547274,
        29491393.0
    ],
    [
        2547275,
        106638136.0
    ],
    [
        2547441,
        29491543.0
    ],
    [
        2547442,
        106638173.0
    ],
    [
        2547561,
        29491619.0
    ],
    [
        2547562,
        106637878.0
    ],
    [
        2547622,
        29491535.0
    ],
    [
        2547623,
        106638110.0
    ],
    [
        2547640,
        29491596.0
    ],
    [
        2547641,
        106638010.0
    ],
    [
        2547671,
        29491623.0
    ],
    [
        2547672,
        106637776.0
    ],
    [
        2547859,
        29491666.0
    ],
    [
        2547860,
        106637863.0
    ],
    [
        2547898,
        29491723.0
    ],
    [
        2547899,
        106637846.0
    ],
    [
        2547955,
        29491611.0
    ],
    [
        2547956,
        106638011.0
    ],
    [
        2548050,
        29491688.0
    ],
    [
        2548051,
        106637814.0
    ],
    [
        2548137,
        29491619.0
    ],
    [
        2548138,
        106637783.0
    ],
    [
        2548169,
        0.0
    ],
    [
        2548170,
        0.0
    ],
    [
        2548238,
        29491731.0
    ],
    [
        2548239,
        106637823.0
    ],
    [
        2548281,
        29491604.0
    ],
    [
        2548282,
        106637796.0
    ],
    [
        2548305,
        29491523.0
    ],
    [
        2548306,
        106637711.0
    ],
    [
        2548336,
        29491468.0
    ],
    [
        2548337,
        106637898.0
    ],
    [
        2548360,
        29491511.0
    ],
    [
        2548361,
        106638005.0
    ],
    [
        2548388,
        29491510.0
    ],
    [
        2548389,
        106637986.0
    ],
    [
        2548548,
        29491880.0
    ],
    [
        2548549,
        106637813.0
    ],
    [
        2548606,
        29491519.0
    ],
    [
        2548607,
        106637943.0
    ],
    [
        2548693,
        29491548.0
    ],
    [
        2548694,
        106637798.0
    ],
    [
        2548739,
        29491718.0
    ],
    [
        2548740,
        106637738.0
    ],
    [
        2548805,
        29492211.0
    ],
    [
        2548806,
        106637953.0
    ],
    [
        2548896,
        0.0
    ],
    [
        2548897,
        0.0
    ],
    [
        2548951,
        29491833.0
    ],
    [
        2548952,
        106637355.0
    ],
    [
        2548961,
        29491833.0
    ],
    [
        2548962,
        106637355.0
    ],
    [
        2548991,
        29491891.0
    ],
    [
        2548992,
        106637470.0
    ],
    [
        2549039,
        29491871.0
    ],
    [
        2549040,
        106637535.0
    ],
    [
        2549080,
        29491888.0
    ],
    [
        2549081,
        106637476.0
    ],
    [
        2549134,
        29492039.0
    ],
    [
        2549135,
        106637661.0
    ],
    [
        2549278,
        29491758.0
    ],
    [
        2549279,
        106637538.0
    ],
    [
        2549310,
        29491733.0
    ],
    [
        2549311,
        106637513.0
    ],
    [
        2549386,
        29491858.0
    ],
    [
        2549387,
        106637625.0
    ],
    [
        2549440,
        29492105.0
    ],
    [
        2549441,
        106637615.0
    ],
    [
        2549473,
        29492129.0
    ],
    [
        2549474,
        106637629.0
    ],
    [
        2549537,
        29492151.0
    ],
    [
        2549538,
        106637373.0
    ],
    [
        2549582,
        29491968.0
    ],
    [
        2549583,
        106637438.0
    ],
    [
        2549681,
        29491829.0
    ],
    [
        2549682,
        106637473.0
    ],
    [
        2549753,
        29491856.0
    ],
    [
        2549754,
        106637566.0
    ],
    [
        2549858,
        29491763.0
    ],
    [
        2549859,
        106637476.0
    ],
    [
        2549890,
        29491836.0
    ],
    [
        2549891,
        106637455.0
    ],
    [
        2549918,
        29491866.0
    ],
    [
        2549919,
        106637456.0
    ],
    [
        2549948,
        29491866.0
    ],
    [
        2549949,
        106637448.0
    ],
    [
        2550066,
        29491721.0
    ],
    [
        2550067,
        106637303.0
    ],
    [
        2550101,
        29491711.0
    ],
    [
        2550102,
        106637496.0
    ],
    [
        2550136,
        29491698.0
    ],
    [
        2550137,
        106637456.0
    ],
    [
        2550162,
        29491825.0
    ],
    [
        2550163,
        106637203.0
    ],
    [
        2550352,
        29492711.0
    ],
    [
        2550354,
        106635919.0
    ],
    [
        2550545,
        29491955.0
    ],
    [
        2550546,
        106637061.0
    ],
    [
        2550577,
        29491951.0
    ],
    [
        2550578,
        106637095.0
    ],
    [
        2550652,
        29492129.0
    ],
    [
        2550653,
        106636346.0
    ],
    [
        2550674,
        29492161.0
    ],
    [
        2550675,
        106637130.0
    ],
    [
        2550700,
        29491866.0
    ],
    [
        2550701,
        106637130.0
    ],
    [
        2550855,
        29491923.0
    ],
    [
        2550856,
        106637198.0
    ],
    [
        2550900,
        29491858.0
    ],
    [
        2550901,
        106637130.0
    ],
    [
        2550932,
        29491820.0
    ],
    [
        2550933,
        106637253.0
    ],
    [
        2551018,
        29491859.0
    ],
    [
        2551020,
        106637151.0
    ],
    [
        2551047,
        29491871.0
    ],
    [
        2551048,
        106637311.0
    ],
    [
        2551095,
        29491838.0
    ],
    [
        2551096,
        106637093.0
    ],
    [
        2551123,
        29491903.0
    ],
    [
        2551125,
        106636471.0
    ],
    [
        2551173,
        29491994.0
    ],
    [
        2551176,
        106636818.0
    ],
    [
        2551274,
        29491983.0
    ],
    [
        2551275,
        106636878.0
    ],
    [
        2551307,
        29492111.0
    ],
    [
        2551308,
        106636980.0
    ],
    [
        2551406,
        29491913.0
    ],
    [
        2551407,
        106636531.0
    ],
    [
        2551447,
        0.0
    ],
    [
        2551449,
        0.0
    ],
    [
        2551479,
        0.0
    ],
    [
        2551480,
        0.0
    ],
    [
        2551611,
        29488340.0
    ],
    [
        2551612,
        106641378.0
    ],
    [
        2551621,
        29491623.0
    ],
    [
        2551622,
        106636888.0
    ],
    [
        2551629,
        29491623.0
    ],
    [
        2551630,
        106636888.0
    ],
    [
        2551705,
        29491896.0
    ],
    [
        2551706,
        106636603.0
    ],
    [
        2551789,
        0.0
    ],
    [
        2551790,
        0.0
    ],
    [
        2551811,
        29491876.0
    ],
    [
        2551812,
        106636513.0
    ],
    [
        2551839,
        0.0
    ],
    [
        2551841,
        0.0
    ],
    [
        2551876,
        29491871.0
    ],
    [
        2551877,
        106636156.0
    ],
    [
        2551912,
        29491835.0
    ],
    [
        2551913,
        106636243.0
    ],
    [
        2551991,
        29492841.0
    ],
    [
        2551992,
        106635661.0
    ],
    [
        2552075,
        0.0
    ],
    [
        2552076,
        0.0
    ],
    [
        2552112,
        29488340.0
    ],
    [
        2552113,
        106641378.0
    ],
    [
        2552166,
        29492285.0
    ],
    [
        2552167,
        106636723.0
    ],
    [
        2552170,
        29492285.0
    ],
    [
        2552171,
        106636723.0
    ],
    [
        2552200,
        29492411.0
    ],
    [
        2552201,
        106636435.0
    ],
    [
        2552220,
        29492478.0
    ],
    [
        2552221,
        106636198.0
    ],
    [
        2552255,
        29492273.0
    ],
    [
        2552256,
        106636461.0
    ],
    [
        2552285,
        29491796.0
    ],
    [
        2552286,
        106637266.0
    ],
    [
        2552327,
        29491826.0
    ],
    [
        2552328,
        106637255.0
    ],
    [
        2552579,
        29492163.0
    ],
    [
        2552580,
        106636878.0
    ],
    [
        2552601,
        29492116.0
    ],
    [
        2552602,
        106636918.0
    ],
    [
        2552658,
        29491835.0
    ],
    [
        2552659,
        106637078.0
    ],
    [
        2552777,
        29491609.0
    ],
    [
        2552778,
        106637719.0
    ],
    [
        2552812,
        29491521.0
    ],
    [
        2552813,
        106637923.0
    ],
    [
        2552974,
        29491576.0
    ],
    [
        2552975,
        106637536.0
    ],
    [
        2553023,
        29491583.0
    ],
    [
        2553024,
        106637395.0
    ],
    [
        2553081,
        29491708.0
    ],
    [
        2553082,
        106637258.0
    ],
    [
        2553164,
        29491721.0
    ],
    [
        2553165,
        106637391.0
    ],
    [
        2553244,
        29491733.0
    ],
    [
        2553245,
        106637496.0
    ],
    [
        2553272,
        29491658.0
    ],
    [
        2553273,
        106637660.0
    ],
    [
        2553361,
        29491586.0
    ],
    [
        2553362,
        106637814.0
    ],
    [
        2553400,
        29491618.0
    ],
    [
        2553401,
        106637763.0
    ],
    [
        2553599,
        29491499.0
    ],
    [
        2553600,
        106637728.0
    ],
    [
        2553639,
        29491564.0
    ],
    [
        2553640,
        106637733.0
    ],
    [
        2553689,
        29491488.0
    ],
    [
        2553690,
        106637791.0
    ],
    [
        2553732,
        29491514.0
    ],
    [
        2553733,
        106637868.0
    ],
    [
        2554010,
        29491513.0
    ],
    [
        2554011,
        106637741.0
    ],
    [
        2554047,
        29491484.0
    ],
    [
        2554048,
        106637940.0
    ],
    [
        2554148,
        29491994.0
    ],
    [
        2554149,
        106636478.0
    ],
    [
        2554174,
        29492061.0
    ],
    [
        2554175,
        106636506.0
    ],
    [
        2554214,
        29492016.0
    ],
    [
        2554215,
        106636413.0
    ],
    [
        2554375,
        29491780.0
    ],
    [
        2554376,
        106636789.0
    ],
    [
        2554442,
        29492425.0
    ],
    [
        2554443,
        106636681.0
    ],
    [
        2554488,
        29492423.0
    ],
    [
        2554489,
        106636878.0
    ],
    [
        2554512,
        0.0
    ],
    [
        2554513,
        0.0
    ],
    [
        2554545,
        29492453.0
    ],
    [
        2554546,
        106636839.0
    ],
    [
        2554567,
        29491873.0
    ],
    [
        2554568,
        106637256.0
    ],
    [
        2554725,
        29488340.0
    ],
    [
        2554726,
        106641378.0
    ],
    [
        2554731,
        29491640.0
    ],
    [
        2554732,
        106635826.0
    ],
    [
        2554768,
        29491658.0
    ],
    [
        2554769,
        106635691.0
    ],
    [
        2554800,
        0.0
    ],
    [
        2554801,
        0.0
    ],
    [
        2554837,
        29488340.0
    ],
    [
        2554838,
        106641378.0
    ],
    [
        2554876,
        29488340.0
    ],
    [
        2554877,
        106641378.0
    ],
    [
        2554907,
        29488340.0
    ],
    [
        2554908,
        106641378.0
    ],
    [
        2554940,
        29488340.0
    ],
    [
        2554941,
        106641378.0
    ],
    [
        2554976,
        29488340.0
    ],
    [
        2554977,
        106641378.0
    ],
    [
        2555143,
        29492215.0
    ],
    [
        2555144,
        106637375.0
    ],
    [
        2555189,
        29492106.0
    ],
    [
        2555190,
        106637488.0
    ],
    [
        2555228,
        29492091.0
    ],
    [
        2555229,
        106637528.0
    ],
    [
        2555381,
        29492188.0
    ],
    [
        2555382,
        106637395.0
    ],
    [
        2555518,
        29491803.0
    ],
    [
        2555519,
        106637574.0
    ],
    [
        2555569,
        29491853.0
    ],
    [
        2555570,
        106637640.0
    ],
    [
        2555668,
        29491814.0
    ],
    [
        2555669,
        106637485.0
    ],
    [
        2555833,
        29491611.0
    ],
    [
        2555834,
        106637428.0
    ],
    [
        2555862,
        29491769.0
    ],
    [
        2555863,
        106637343.0
    ],
    [
        2555899,
        29491793.0
    ],
    [
        2555900,
        106637263.0
    ],
    [
        2555948,
        29491908.0
    ],
    [
        2555949,
        106636868.0
    ],
    [
        2556141,
        0.0
    ],
    [
        2556142,
        0.0
    ],
    [
        2556220,
        29488340.0
    ],
    [
        2556221,
        106641378.0
    ],
    [
        2556257,
        29488340.0
    ],
    [
        2556258,
        106641378.0
    ],
    [
        2556292,
        29491956.0
    ],
    [
        2556293,
        106636978.0
    ],
    [
        2556342,
        29491790.0
    ],
    [
        2556344,
        106637655.0
    ],
    [
        2556371,
        0.0
    ],
    [
        2556372,
        0.0
    ],
    [
        2556446,
        29488340.0
    ],
    [
        2556447,
        106641378.0
    ],
    [
        2556471,
        29491381.0
    ],
    [
        2556472,
        106637731.0
    ],
    [
        2556483,
        29491381.0
    ],
    [
        2556484,
        106637731.0
    ],
    [
        2556517,
        29491658.0
    ],
    [
        2556518,
        106637786.0
    ],
    [
        2556623,
        29491578.0
    ],
    [
        2556624,
        106637691.0
    ],
    [
        2556656,
        29491586.0
    ],
    [
        2556657,
        106637586.0
    ],
    [
        2556693,
        29491588.0
    ],
    [
        2556694,
        106637616.0
    ],
    [
        2556772,
        29491559.0
    ],
    [
        2556773,
        106637486.0
    ],
    [
        2556796,
        29491773.0
    ],
    [
        2556797,
        106636535.0
    ],
    [
        2556872,
        29491673.0
    ],
    [
        2556873,
        106637521.0
    ],
    [
        2556912,
        29491686.0
    ],
    [
        2556913,
        106637383.0
    ],
    [
        2556941,
        29491570.0
    ],
    [
        2556942,
        106637844.0
    ],
    [
        2557055,
        29491426.0
    ],
    [
        2557056,
        106638400.0
    ],
    [
        2557269,
        29488340.0
    ],
    [
        2557270,
        106641378.0
    ],
    [
        2557317,
        29488340.0
    ],
    [
        2557318,
        106641378.0
    ],
    [
        2557435,
        29488340.0
    ],
    [
        2557436,
        106641378.0
    ],
    [
        2557466,
        29488340.0
    ],
    [
        2557467,
        106641378.0
    ],
    [
        2557483,
        29491454.0
    ],
    [
        2557484,
        106638258.0
    ],
    [
        2557552,
        29491518.0
    ],
    [
        2557553,
        106637038.0
    ],
    [
        2557598,
        29491496.0
    ],
    [
        2557599,
        106637583.0
    ],
    [
        2557630,
        29491525.0
    ],
    [
        2557631,
        106637586.0
    ],
    [
        2557657,
        29491640.0
    ],
    [
        2557658,
        106636956.0
    ],
    [
        2557743,
        29491566.0
    ],
    [
        2557744,
        106637140.0
    ],
    [
        2557767,
        29491568.0
    ],
    [
        2557768,
        106637213.0
    ],
    [
        2557912,
        29491513.0
    ],
    [
        2557913,
        106637186.0
    ],
    [
        2558031,
        29491466.0
    ],
    [
        2558032,
        106637356.0
    ],
    [
        2558089,
        29491563.0
    ],
    [
        2558090,
        106637340.0
    ],
    [
        2558128,
        29491625.0
    ],
    [
        2558129,
        106637490.0
    ],
    [
        2558146,
        29491604.0
    ],
    [
        2558147,
        106637643.0
    ],
    [
        2558258,
        29491571.0
    ],
    [
        2558259,
        106637190.0
    ],
    [
        2558500,
        29491384.0
    ],
    [
        2558501,
        106638241.0
    ],
    [
        2558716,
        29491603.0
    ],
    [
        2558717,
        106637664.0
    ],
    [
        2558821,
        29491593.0
    ],
    [
        2558822,
        106637886.0
    ],
    [
        2558948,
        29491578.0
    ],
    [
        2558949,
        106637846.0
    ],
    [
        2559049,
        29491593.0
    ],
    [
        2559050,
        106637668.0
    ],
    [
        2559162,
        29491691.0
    ],
    [
        2559163,
        106637290.0
    ],
    [
        2559230,
        29491616.0
    ],
    [
        2559231,
        106637514.0
    ],
    [
        2559279,
        29491568.0
    ],
    [
        2559280,
        106637571.0
    ],
    [
        2559500,
        29491508.0
    ],
    [
        2559501,
        106637809.0
    ],
    [
        2559629,
        29491481.0
    ],
    [
        2559630,
        106637843.0
    ],
    [
        2559710,
        29491510.0
    ],
    [
        2559711,
        106637740.0
    ],
    [
        2559737,
        29491514.0
    ],
    [
        2559738,
        106637841.0
    ],
    [
        2559780,
        29491499.0
    ],
    [
        2559781,
        106637858.0
    ],
    [
        2559808,
        29491781.0
    ],
    [
        2559809,
        106637719.0
    ],
    [
        2559841,
        29491633.0
    ],
    [
        2559842,
        106637816.0
    ],
    [
        2559874,
        29491728.0
    ],
    [
        2559875,
        106637653.0
    ],
    [
        2559907,
        29491671.0
    ],
    [
        2559909,
        106637581.0
    ],
    [
        2560196,
        29491546.0
    ],
    [
        2560197,
        106637881.0
    ],
    [
        2560222,
        29491551.0
    ],
    [
        2560223,
        106637823.0
    ],
    [
        2560251,
        29491550.0
    ],
    [
        2560252,
        106637821.0
    ],
    [
        2560512,
        29491558.0
    ],
    [
        2560513,
        106637906.0
    ],
    [
        2560541,
        29491611.0
    ],
    [
        2560542,
        106637761.0
    ],
    [
        2560628,
        29491516.0
    ],
    [
        2560629,
        106638041.0
    ],
    [
        2560707,
        29491523.0
    ],
    [
        2560708,
        106638011.0
    ],
    [
        2560746,
        29491556.0
    ],
    [
        2560747,
        106637910.0
    ],
    [
        2560997,
        29491510.0
    ],
    [
        2560998,
        106638005.0
    ],
    [
        2561116,
        29491548.0
    ],
    [
        2561117,
        106637921.0
    ],
    [
        2561144,
        29491541.0
    ],
    [
        2561145,
        106637916.0
    ],
    [
        2561438,
        29491558.0
    ],
    [
        2561439,
        106638003.0
    ],
    [
        2561468,
        29491551.0
    ],
    [
        2561469,
        106637921.0
    ],
    [
        2561509,
        29491601.0
    ],
    [
        2561510,
        106637918.0
    ],
    [
        2561620,
        29491578.0
    ],
    [
        2561621,
        106637860.0
    ],
    [
        2561760,
        29491533.0
    ],
    [
        2561761,
        106637911.0
    ],
    [
        2561803,
        29491529.0
    ],
    [
        2561804,
        106637840.0
    ],
    [
        2561830,
        29491420.0
    ],
    [
        2561831,
        106638101.0
    ],
    [
        2561869,
        29491526.0
    ],
    [
        2561870,
        106637964.0
    ],
    [
        2562004,
        29491519.0
    ],
    [
        2562006,
        106637911.0
    ],
    [
        2562037,
        29491518.0
    ],
    [
        2562038,
        106637650.0
    ],
    [
        2562078,
        29491531.0
    ],
    [
        2562079,
        106637738.0
    ],
    [
        2562252,
        29491526.0
    ],
    [
        2562253,
        106637745.0
    ],
    [
        2562329,
        29491551.0
    ],
    [
        2562330,
        106637613.0
    ],
    [
        2562502,
        29491533.0
    ],
    [
        2562504,
        106637616.0
    ],
    [
        2562648,
        29491541.0
    ],
    [
        2562649,
        106637681.0
    ],
    [
        2562686,
        29491556.0
    ],
    [
        2562687,
        106637773.0
    ],
    [
        2562760,
        29491571.0
    ],
    [
        2562761,
        106637509.0
    ],
    [
        2562784,
        29491566.0
    ],
    [
        2562785,
        106637445.0
    ],
    [
        2562881,
        29491506.0
    ],
    [
        2562882,
        106637441.0
    ],
    [
        2563073,
        29491656.0
    ],
    [
        2563074,
        106637531.0
    ],
    [
        2563269,
        29491551.0
    ],
    [
        2563270,
        106638144.0
    ],
    [
        2563303,
        29491561.0
    ],
    [
        2563304,
        106638201.0
    ],
    [
        2563473,
        29491521.0
    ],
    [
        2563474,
        106637466.0
    ],
    [
        2563654,
        29491486.0
    ],
    [
        2563655,
        106637791.0
    ],
    [
        2563728,
        29491523.0
    ],
    [
        2563729,
        106637846.0
    ],
    [
        2563799,
        29491535.0
    ],
    [
        2563800,
        106637991.0
    ],
    [
        2563846,
        29491593.0
    ],
    [
        2563847,
        106637850.0
    ],
    [
        2563931,
        29491564.0
    ],
    [
        2563932,
        106637803.0
    ],
    [
        2563978,
        29491550.0
    ],
    [
        2563979,
        106637883.0
    ],
    [
        2564025,
        29491634.0
    ],
    [
        2564026,
        106637811.0
    ],
    [
        2564062,
        29491586.0
    ],
    [
        2564063,
        106637705.0
    ],
    [
        2564107,
        29491551.0
    ],
    [
        2564108,
        106637835.0
    ],
    [
        2564149,
        29491603.0
    ],
    [
        2564150,
        106637784.0
    ],
    [
        2564289,
        29491468.0
    ],
    [
        2564290,
        106637933.0
    ],
    [
        2564361,
        29491541.0
    ],
    [
        2564363,
        106637708.0
    ],
    [
        2564427,
        29491521.0
    ],
    [
        2564428,
        106637868.0
    ],
    [
        2564700,
        29491581.0
    ],
    [
        2564701,
        106637373.0
    ],
    [
        2564726,
        29491581.0
    ],
    [
        2564727,
        106637373.0
    ],
    [
        2564907,
        0.0
    ],
    [
        2564908,
        0.0
    ],
    [
        2564950,
        29491533.0
    ],
    [
        2564951,
        106637701.0
    ],
    [
        2564989,
        0.0
    ],
    [
        2564991,
        0.0
    ],
    [
        2565017,
        29491701.0
    ],
    [
        2565018,
        106638054.0
    ],
    [
        2565049,
        29491654.0
    ],
    [
        2565050,
        106637615.0
    ],
    [
        2565219,
        29491531.0
    ],
    [
        2565221,
        106637708.0
    ],
    [
        2565352,
        29491525.0
    ],
    [
        2565353,
        106637451.0
    ],
    [
        2565510,
        29491451.0
    ],
    [
        2565511,
        106637326.0
    ],
    [
        2565549,
        29491443.0
    ],
    [
        2565551,
        106637398.0
    ],
    [
        2565637,
        29491568.0
    ],
    [
        2565639,
        106637634.0
    ],
    [
        2565730,
        29491538.0
    ],
    [
        2565731,
        106637660.0
    ],
    [
        2565865,
        29491633.0
    ],
    [
        2565866,
        106637814.0
    ],
    [
        2565901,
        29491660.0
    ],
    [
        2565902,
        106637988.0
    ],
    [
        2565935,
        29491558.0
    ],
    [
        2565936,
        106637978.0
    ],
    [
        2566014,
        29491558.0
    ],
    [
        2566015,
        106637920.0
    ],
    [
        2566216,
        29491638.0
    ],
    [
        2566217,
        106637689.0
    ],
    [
        2566259,
        29491670.0
    ],
    [
        2566260,
        106637886.0
    ],
    [
        2566332,
        29491601.0
    ],
    [
        2566333,
        106637871.0
    ],
    [
        2566407,
        29491420.0
    ],
    [
        2566408,
        106638086.0
    ],
    [
        2566439,
        29491499.0
    ],
    [
        2566440,
        106638095.0
    ],
    [
        2566661,
        29491663.0
    ],
    [
        2566662,
        106637795.0
    ],
    [
        2566756,
        29491693.0
    ],
    [
        2566758,
        106637826.0
    ],
    [
        2566785,
        29491709.0
    ],
    [
        2566786,
        106637835.0
    ],
    [
        2566813,
        29491753.0
    ],
    [
        2566814,
        106637751.0
    ],
    [
        2566850,
        29491943.0
    ],
    [
        2566851,
        106637586.0
    ],
    [
        2566923,
        29491934.0
    ],
    [
        2566924,
        106637539.0
    ],
    [
        2567039,
        29491975.0
    ],
    [
        2567040,
        106637576.0
    ],
    [
        2567084,
        29491951.0
    ],
    [
        2567085,
        106637606.0
    ],
    [
        2567216,
        29491661.0
    ],
    [
        2567217,
        106637708.0
    ],
    [
        2567246,
        29491553.0
    ],
    [
        2567247,
        106637790.0
    ],
    [
        2567359,
        29491528.0
    ],
    [
        2567360,
        106637820.0
    ],
    [
        2567389,
        29491580.0
    ],
    [
        2567390,
        106637763.0
    ],
    [
        2567528,
        29491568.0
    ],
    [
        2567529,
        106637840.0
    ],
    [
        2567637,
        29491458.0
    ],
    [
        2567639,
        106637846.0
    ],
    [
        2567666,
        29491486.0
    ],
    [
        2567667,
        106637896.0
    ],
    [
        2567731,
        29491493.0
    ],
    [
        2567732,
        106637903.0
    ],
    [
        2567896,
        29491983.0
    ],
    [
        2567897,
        106637604.0
    ],
    [
        2567949,
        0.0
    ],
    [
        2567950,
        0.0
    ],
    [
        2568004,
        29491766.0
    ],
    [
        2568005,
        106637470.0
    ],
    [
        2568045,
        29491790.0
    ],
    [
        2568046,
        106637606.0
    ],
    [
        2568101,
        29491738.0
    ],
    [
        2568102,
        106637779.0
    ],
    [
        2568219,
        29491946.0
    ],
    [
        2568220,
        106637763.0
    ],
    [
        2568392,
        29491776.0
    ],
    [
        2568393,
        106637856.0
    ],
    [
        2568437,
        29491773.0
    ],
    [
        2568438,
        106637781.0
    ],
    [
        2568486,
        0.0
    ],
    [
        2568487,
        0.0
    ],
    [
        2568518,
        29488340.0
    ],
    [
        2568519,
        106641378.0
    ],
    [
        2568591,
        29488340.0
    ],
    [
        2568592,
        106641378.0
    ],
    [
        2568794,
        29491625.0
    ],
    [
        2568795,
        106637711.0
    ],
    [
        2568847,
        29491645.0
    ],
    [
        2568848,
        106637623.0
    ],
    [
        2569078,
        29488340.0
    ],
    [
        2569079,
        106641378.0
    ],
    [
        2569255,
        29488340.0
    ],
    [
        2569256,
        106641378.0
    ],
    [
        2569302,
        29488340.0
    ],
    [
        2569303,
        106641378.0
    ],
    [
        2569491,
        29488340.0
    ],
    [
        2569492,
        106641378.0
    ],
    [
        2569678,
        29488340.0
    ],
    [
        2569679,
        106641378.0
    ],
    [
        2569735,
        29491393.0
    ],
    [
        2569736,
        106638173.0
    ],
    [
        2569786,
        29491496.0
    ],
    [
        2569787,
        106637724.0
    ],
    [
        2569841,
        29491543.0
    ],
    [
        2569842,
        106637716.0
    ],
    [
        2569871,
        29491506.0
    ],
    [
        2569872,
        106637790.0
    ],
    [
        2570266,
        29488340.0
    ],
    [
        2570267,
        106641378.0
    ],
    [
        2570404,
        29493815.0
    ],
    [
        2570405,
        106635635.0
    ],
    [
        2570476,
        29491633.0
    ],
    [
        2570477,
        106637696.0
    ],
    [
        2570745,
        29491631.0
    ],
    [
        2570746,
        106637241.0
    ],
    [
        2570779,
        29491646.0
    ],
    [
        2570780,
        106637310.0
    ],
    [
        2570911,
        29491563.0
    ],
    [
        2570912,
        106637961.0
    ],
    [
        2571099,
        29491540.0
    ],
    [
        2571101,
        106638116.0
    ],
    [
        2571208,
        29491580.0
    ],
    [
        2571209,
        106637691.0
    ],
    [
        2571320,
        29491696.0
    ],
    [
        2571321,
        106637918.0
    ],
    [
        2571429,
        29491744.0
    ],
    [
        2571430,
        106638075.0
    ],
    [
        2571605,
        29491716.0
    ],
    [
        2571606,
        106638070.0
    ],
    [
        2571779,
        29491493.0
    ],
    [
        2571780,
        106638083.0
    ],
    [
        2571971,
        29491518.0
    ],
    [
        2571972,
        106637911.0
    ],
    [
        2572064,
        29491468.0
    ],
    [
        2572065,
        106638061.0
    ],
    [
        2572100,
        29491508.0
    ],
    [
        2572101,
        106637765.0
    ],
    [
        2572133,
        29491535.0
    ],
    [
        2572134,
        106637751.0
    ],
    [
        2572176,
        29491516.0
    ],
    [
        2572177,
        106637848.0
    ],
    [
        2572325,
        29491521.0
    ],
    [
        2572326,
        106638256.0
    ],
    [
        2572371,
        29491574.0
    ],
    [
        2572372,
        106638023.0
    ],
    [
        2572416,
        29491589.0
    ],
    [
        2572417,
        106637961.0
    ],
    [
        2572442,
        29491679.0
    ],
    [
        2572443,
        106637700.0
    ],
    [
        2572487,
        29491654.0
    ],
    [
        2572488,
        106637773.0
    ],
    [
        2572591,
        29491546.0
    ],
    [
        2572592,
        106637765.0
    ],
    [
        2572627,
        29491538.0
    ],
    [
        2572628,
        106637733.0
    ],
    [
        2572664,
        29491580.0
    ],
    [
        2572665,
        106637753.0
    ],
    [
        2572876,
        29491538.0
    ],
    [
        2572877,
        106637866.0
    ],
    [
        2572927,
        29491556.0
    ],
    [
        2572928,
        106637856.0
    ],
    [
        2573044,
        29491604.0
    ],
    [
        2573045,
        106637825.0
    ],
    [
        2573080,
        29491513.0
    ],
    [
        2573081,
        106637816.0
    ],
    [
        2573108,
        29491484.0
    ],
    [
        2573109,
        106637743.0
    ],
    [
        2573238,
        29491608.0
    ],
    [
        2573239,
        106637793.0
    ],
    [
        2573299,
        29491619.0
    ],
    [
        2573300,
        106637800.0
    ],
    [
        2573411,
        29491564.0
    ],
    [
        2573412,
        106637876.0
    ],
    [
        2573493,
        29491525.0
    ],
    [
        2573494,
        106637928.0
    ],
    [
        2573525,
        29491555.0
    ],
    [
        2573526,
        106637906.0
    ],
    [
        2573563,
        29491540.0
    ],
    [
        2573564,
        106637941.0
    ],
    [
        2573713,
        29491510.0
    ],
    [
        2573714,
        106637931.0
    ],
    [
        2573770,
        29491528.0
    ],
    [
        2573771,
        106637886.0
    ],
    [
        2573841,
        29491535.0
    ],
    [
        2573842,
        106637666.0
    ],
    [
        2573891,
        29491648.0
    ],
    [
        2573892,
        106637684.0
    ],
    [
        2573966,
        29491563.0
    ],
    [
        2573967,
        106637913.0
    ],
    [
        2574336,
        29491658.0
    ],
    [
        2574337,
        106637730.0
    ],
    [
        2574441,
        29491621.0
    ],
    [
        2574442,
        106637735.0
    ],
    [
        2574473,
        29491540.0
    ],
    [
        2574474,
        106637840.0
    ],
    [
        2574566,
        29491608.0
    ],
    [
        2574567,
        106637706.0
    ],
    [
        2574652,
        29491626.0
    ],
    [
        2574653,
        106637678.0
    ],
    [
        2574692,
        29491471.0
    ],
    [
        2574693,
        106637746.0
    ],
    [
        2574732,
        29491454.0
    ],
    [
        2574733,
        106637826.0
    ],
    [
        2574902,
        29491518.0
    ],
    [
        2574903,
        106637915.0
    ],
    [
        2575062,
        29491166.0
    ],
    [
        2575063,
        106638031.0
    ],
    [
        2575228,
        29491315.0
    ],
    [
        2575229,
        106638066.0
    ],
    [
        2575256,
        29491400.0
    ],
    [
        2575257,
        106638063.0
    ],
    [
        2575289,
        29491351.0
    ],
    [
        2575290,
        106638046.0
    ],
    [
        2575460,
        29491544.0
    ],
    [
        2575461,
        106638158.0
    ],
    [
        2575540,
        29491544.0
    ],
    [
        2575541,
        106637994.0
    ],
    [
        2575705,
        29491469.0
    ],
    [
        2575706,
        106637966.0
    ],
    [
        2575826,
        29491480.0
    ],
    [
        2575827,
        106637861.0
    ],
    [
        2575962,
        29491510.0
    ],
    [
        2575963,
        106637871.0
    ],
    [
        2575995,
        29491483.0
    ],
    [
        2575996,
        106637921.0
    ],
    [
        2576047,
        29491583.0
    ],
    [
        2576048,
        106637876.0
    ],
    [
        2576085,
        29491503.0
    ],
    [
        2576086,
        106637983.0
    ],
    [
        2576164,
        29491675.0
    ],
    [
        2576165,
        106637891.0
    ],
    [
        2576235,
        29491356.0
    ],
    [
        2576236,
        106637923.0
    ],
    [
        2576271,
        29491536.0
    ],
    [
        2576272,
        106637989.0
    ],
    [
        2576411,
        29491553.0
    ],
    [
        2576412,
        106637948.0
    ],
    [
        2576510,
        29491508.0
    ],
    [
        2576511,
        106637911.0
    ],
    [
        2576617,
        29491681.0
    ],
    [
        2576618,
        106638100.0
    ],
    [
        2576728,
        29488340.0
    ],
    [
        2576729,
        106641378.0
    ],
    [
        2576816,
        29488340.0
    ],
    [
        2576817,
        106641378.0
    ],
    [
        2576859,
        29488340.0
    ],
    [
        2576860,
        106641378.0
    ],
    [
        2577063,
        29491285.0
    ],
    [
        2577064,
        106638075.0
    ],
    [
        2577120,
        29488340.0
    ],
    [
        2577121,
        106641378.0
    ],
    [
        2577280,
        0.0
    ],
    [
        2577281,
        0.0
    ],
    [
        2577319,
        29491300.0
    ],
    [
        2577320,
        106637551.0
    ],
    [
        2577482,
        29491633.0
    ],
    [
        2577483,
        106638294.0
    ],
    [
        2577611,
        29491436.0
    ],
    [
        2577612,
        106638266.0
    ],
    [
        2577722,
        29492405.0
    ],
    [
        2577723,
        106637851.0
    ],
    [
        2578036,
        29491451.0
    ],
    [
        2578037,
        106638051.0
    ],
    [
        2578181,
        29491519.0
    ],
    [
        2578182,
        106638096.0
    ],
    [
        2578412,
        29491529.0
    ],
    [
        2578413,
        106637826.0
    ],
    [
        2578474,
        29491561.0
    ],
    [
        2578475,
        106637880.0
    ],
    [
        2578541,
        29491578.0
    ],
    [
        2578542,
        106638051.0
    ],
    [
        2578569,
        29491739.0
    ],
    [
        2578570,
        106637843.0
    ],
]